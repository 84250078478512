<template>
  <div class="container-fluid">
    <h4 class="fw-bold text-primary">User Settings</h4>
    <p class="alert alert-primary">
      <i class="far fa-info-circle me-1"></i>
      These are the details which you use to log in to Swandoola. Make sure you have access to the address provided here as it is required to gain access to Swandoola.
    </p>
    <div class="row mb-4">
      <div class="col">
        <label>Email Address</label>
        <input v-model="user.email" type="email" class="form-control" @change="updateEmail" required />
      </div>
      <div class="col">
        <label>Phone Number <small v-if="user.require_tfa" class="text-danger">Disable 2FA to update phone number</small> </label>
        <phone-number-input v-model="user.phone"
                            :disabled="user.require_tfa"
                            @update="updatePhone"
                            default-country-code="GB"
                            :preferred-countries="['GB', 'MT', 'GR', 'ES', 'FR', 'BE', 'DE']" />
      </div>
    </div>

    <h4 class="fw-bold text-primary">Two-Factor Authentication (2FA)</h4>
    <div v-if="!pendingTfaConfirm">
      <p class="alert alert-primary">
        <i class="far fa-info-circle me-1"></i>
        Enabling 2FA will require you to confirm your identity via an SMS code sent to your mobile phone number provided above before you are able to access the system.
      </p>
      <toggle-button v-model="user.require_tfa"
                     @change="updateTfa"
                     class="my-auto"
                     :color="'#03006b'" />
      <label class="custom-control-label ms-1">
        Enable Two-Factor Authentication
      </label>
    </div>
    <div class="text-center" v-else>
      <p class="alert alert-primary">
        Please enter the 6 digit code we have just sent to your provided phone number.
      </p>
      <input type="text"
             class="form-control mx-auto mb-2 w-25 text-center"
             v-model="confirmTfaCode"
             placeholder="000000"/>
      <button class="btn btn-primary" @click="confirmTfa" :disabled="saving">
        <busy :visible="saving" />
        Confirm
      </button>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";


export default {
  props: [],
  data() {
    return {
      emailInUse: false,
      saving: false,
      pendingTfaConfirm: false,
      confirmTfaCode: null,
    };
  },
  methods: {
    updateEmail() {
      this.saving = true;
      this.$axios.post(process.env.VUE_APP_API_URL + "/settings/user/" + this.user.id + "/update-email", this.user)
          .then(({ data }) => {
            this.$EventBus.$emit("refreshUser");
            this.$EventBus.$emit("alert", data);
            this.saving = false;
          })
    },
    updatePhone: debounce(function() {
      const original = this.user.phone;
      this.saving = true;
      this.$axios.post(process.env.VUE_APP_API_URL + "/settings/user/" + this.user.id + "/update-phone", this.user)
          .then(({ data }) => {
            this.$EventBus.$emit("refreshUser");
            this.$EventBus.$emit("alert", data);
            this.saving = false;
          })
    }, 800),
    updateTfa() {
      this.saving = true;
      this.$axios.post(process.env.VUE_APP_API_URL + "/settings/user/" + this.user.id + "/update-tfa", this.user)
          .then(({ data }) => {
            this.saving = false;
            if (data.success){
              this.pendingTfaConfirm = true;
            }
            this.$EventBus.$emit("refreshUser");
            this.$EventBus.$emit("alert", data);
          })
    },
    confirmTfa() {
      this.saving = true;
      this.$axios.post(process.env.VUE_APP_API_URL + "/settings/user/" + this.user.id + "/confirm-tfa", {
        tfa_code: this.confirmTfaCode
      }).then(({ data }) => {
          this.saving = false;
          if (data.success) {
            this.pendingTfaConfirm = false;
          }
          this.$EventBus.$emit("refreshUser");
          this.$EventBus.$emit("alert", data);
        })
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$stash.path;
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    }
  },
  mounted() {
  },
};
</script>



<style>
</style>
